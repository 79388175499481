import React from "react";
import cx from "classnames";
import Button from "@components/Button";
import Link from "@components/Link";
import { useQuery } from "@apollo/client";
import getCurrenciesQuery from "./get-currencies.graphql";
import { Form, Formik } from "formik";
import { PasswordField, SelectBox, TextField } from "../Formik";
import { useIntl } from "react-intl";
import { openNavigation } from "@actions/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../Provide/RouterContext";
import useSWR from "swr";
import getCountriesQuery from "./get-countries.graphql";
import { getIpInfo } from "../../data/model/session";
import { template } from "../../core/utils";
import { useWallet } from "../Provide/WalletContext";
import getPromotionBlockQuery from "./get-promotion-block.graphql";
import { useAuth } from "../Provide/AuthContext";
import { useDevice } from "../Provide/DeviceContext";
import { cfImgUrl } from "../../config";
import { Helmet } from "react-helmet-async";
import history from "../../core/history";
import { localeUrl } from "../../core/url";
import { useMediaQuery } from "react-responsive";
import { isBrowser } from "browser-or-node";
import { getPlayerDetails } from "../../data/model/profile";

const LandingOffer = ({ initialPromotion }) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const router = useRouter();
  const { currency } = useWallet();

  const { data: playerDetails } = useSWR(
    isAuthenticated ? "/api/player" : null,
    getPlayerDetails,
    { refreshInterval: 60000 }
  );

  const { loading, error, data } = useQuery(getPromotionBlockQuery, {
    variables: {
      locale: intl.locale,
      route: router.routename,
      isAuthenticated,
      statuses:
        isAuthenticated && playerDetails && playerDetails?.statuses
          ? playerDetails?.statuses.map((x) => x?.id)
          : [],
    },
  });

  const minShowWidth = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const mainPromo =
    initialPromotion !== undefined
      ? initialPromotion?.promotions || []
      : data?.PromotionBlock;
  const promoBlock = mainPromo?.find(
    (item) =>
      (item.component === "PromotionblockLoggedIn" && isAuthenticated) ||
      (item.component === "PromotionblockLoggedOut" && !isAuthenticated)
  );

  let sourceFile = null;
  let position = null;

  if (!isBrowser) {
    sourceFile = isMobile?.any
      ? `${COCKPITCFG.url}/${promoBlock?.settings?.backgroundMobile?.path}`
      : `${COCKPITCFG.url}/${promoBlock?.settings?.backgroundDesktop?.path}`;
    position = isMobile?.any
      ? promoBlock?.settings?.positionMobile
      : promoBlock?.settings?.positionDesktop;
  } else {
    sourceFile =
      minShowWidth && isLandscape
        ? `${COCKPITCFG.url}/${promoBlock?.settings?.backgroundDesktop?.path}`
        : `${COCKPITCFG.url}/${promoBlock?.settings?.backgroundMobile?.path}`;
    position =
      minShowWidth && isLandscape
        ? promoBlock?.settings?.positionDesktop
        : promoBlock?.settings?.positionMobile;
  }

  if (!sourceFile) {
    return null;
  }

  const imageurl =
    sourceFile?.includes(".svg") || !cfImgUrl
      ? sourceFile
      : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${sourceFile}`;

  let offer = promoBlock?.settings?.offer ?? "";
  const paramsPattern = /[^{\}]+(?=})/g;
  let extractParams = offer.match(paramsPattern);

  extractParams?.map((item) => {
    let replaceWith = "";
    const [func, value] = item.split("|");
    switch (func) {
      case "currency":
        replaceWith = intl.formatNumber(value, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
    }
    offer = offer.replace(`{${item}}`, replaceWith);
  });

  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );

  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const { error: currencyError, data: currencies } =
    useQuery(getCurrenciesQuery);
  const { error: countryError, data: countries } = useQuery(getCountriesQuery);
  const [lang, country] = intl.locale.split("-");

  const searchCountry = ["row", "eu", "us"].includes(country)
    ? ipInfo?.country_code
    : country.toUpperCase();
  const countryInfo = countries?.countries?.find(
    (item) => item?.code === searchCountry
  );
  const currencyInfo = currencies?.currencies?.find(
    (item) => item?.code === currency
  );
  const localeInfo = {
    ...countryInfo,
    ...currencyInfo,
    currency: currencyInfo?.code,
    code: countryInfo?.code,
  };

  const submit = async (values, { setStatus, resetForm, setFieldError }) =>
    dispatch(openNavigation("Registration", values));

  const newLocale = intl.locale === "en-us" ? "en-row" : intl.locale;
  console.log("promoBlock", promoBlock);

  if (!promoBlock || promoBlock?.length === 0) {
    return null;
  }

  const openReg = (section) => dispatch(openNavigation(section ?? "Root"));

  return (
    <section
      className={cx(
        "relative flex flex-col justify-end items-center bg-right-top md:bg-center bg-cover pt-50 bg-brand-500",
        isNavigationOpen ? " filter md:blur-sm " : "",
        { "min-h-500": isMobile.any && isAuthenticated }
      )}
      style={{ backgroundImage: `url(${imageurl})`, paddingBottom: `30px` }}
    >
      <Helmet>
        <link rel="preload" href={imageurl} as="image" />
      </Helmet>
      <div className="z-30 w-full px-3 md:container md:px-28">
        <div
          className={cx("w-full max-w-xxl flex flex-col", {
            "justify-start items-start text-left": position === "Top Left",
            "justify-center items-start text-left": position === "Center left",
            "justify-end items-start text-left": position === "Bottom left",
            "justify-start items-center text-center": position === "Top center",
            "justify-center items-center text-center":
              position === "Center Center" || position === "",
            "justify-end items-center text-center":
              position === "Center Bottom",
            "justify-start items-end text-right": position === "Right Top",
            "justify-center items-end text-right": position === "Right Center",
            "justify-end items-end text-right": position === "Right bottom",
          })}
          key={isBrowser ? "browser" : "server"}
        >
          {promoBlock?.settings?.offer && (
            <div
              className="w-full"
              dangerouslySetInnerHTML={{ __html: offer }}
            />
          )}
          {promoBlock?.settings?.termsLine && (
            <Link
              trackName="Promoblock"
              trackParams={{
                component: "LandingOffer",
                item: promoBlock?.settings?.offer,
              }}
              href={template(promoBlock?.settings?.termsLink, {
                locale: newLocale,
              })}
              className="block mb-6 text-14 text-gray"
            >
              {promoBlock?.settings?.termsLine}
            </Link>
          )}
          {promoBlock?.settings?.hasRegistration && (
            <>
              {!isAuthenticated && (
                <>
                  <h6 className="hidden mb-5 font-bold text-white text-24 md:block">
                    {promoBlock?.settings?.registrationHeader}
                  </h6>
                  <div className="w-full md:max-w-sm">
                    <Formik
                      onSubmit={submit}
                      enableReinitialize
                      initialValues={{
                        email: "",
                        password: "",
                        currency: {
                          name: localeInfo.currency,
                          text: `${localeInfo.currency} (${localeInfo.symbol})`,
                        },
                      }}
                      // validationSchema={step.props.validationSchema}
                      //validateOnChange={false}
                    >
                      {({ values, status, setStatus, errors, touched }) => {
                        return (
                          <Form>
                            <div className="w-full md:max-w-sm">
                              <div className="hidden md:block">
                                <TextField
                                  name="email"
                                  label={intl.formatMessage({
                                    defaultMessage: "E-mail",
                                    description: "Account section",
                                  })}
                                  className="mt-4"
                                  showFeedbackIcon={false}
                                  showFeedbackText={false}
                                />

                                <PasswordField
                                  name="password"
                                  label={intl.formatMessage({
                                    defaultMessage: "Password",
                                    description: "Account section",
                                  })}
                                  className="mt-4"
                                  showFeedbackIcon={false}
                                  showFeedbackText={false}
                                />

                                <SelectBox
                                  name="currency"
                                  options={currencies?.currencies?.map(
                                    (item, i) => ({
                                      name: item.code,
                                      text: `${item.code} (${item.symbol})`,
                                    })
                                  )}
                                  className="w-full mt-4 mb-5 "
                                  showFeedbackIcon={false}
                                  showFeedbackText={false}
                                />
                              </div>
                              {/*<div className="relative ">*/}
                              {/*  <select className="w-full px-3 py-2 text-white border rounded-lg bg-brand-600 border-brand-500 h-14">*/}
                              {/*    {*/}
                              {/*      data?.currencies?.map(item => <option>{item.code} ({item.symbol})</option>)*/}
                              {/*    }*/}
                              {/*  </select>*/}
                              {/*</div>*/}
                              <Button.Action
                                trackName="Create Account"
                                trackParams={{ component: "LandingOffer" }}
                                className="inline-flex items-center justify-between w-full btn "
                                type="submit"
                                arrow="right"
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Create account",
                                  description: "Header of section",
                                })}{" "}
                              </Button.Action>
                              <p className="text-sm text-gray">
                                {intl.formatMessage(
                                  {
                                    defaultMessage: "{ctaline}",
                                    description: "Account section",
                                  },
                                  { ctaline: promoBlock?.settings?.ctaLine }
                                )}
                              </p>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </>
              )}
            </>
          )}
          {promoBlock?.settings?.ctabutton && (
            <Button.Action
              trackName="Offer CTA"
              trackParams={{ component: "LandingOffer" }}
              className="inline-flex items-center justify-between btn w-min fe-pulse"
              style={{ minWidth: "250px" }}
              type="submit"
              arrow="right"
              onClick={() => {
                if (promoBlock?.settings?.ctaroute.route === "register") {
                  openReg("Registration");
                } else {
                  history.push(
                    localeUrl(
                      promoBlock?.settings?.ctaroute.route,
                      intl.locale,
                      promoBlock?.settings?.ctaparams
                    )
                  );
                }
              }}
              to={promoBlock?.settings?.ctaroute.route}
              params={promoBlock?.settings?.ctaparams}
              transition={promoBlock?.settings?.ctaroute.route !== "register"}
            >
              {promoBlock?.settings?.ctabutton}
            </Button.Action>
          )}
        </div>
      </div>
      {!isAuthenticated && (
        <div className="z-20 w-full h-1 px-3 mx-auto my-6 md:px-28 md:px-0 md:container md:my-9">
          <div className="w-full border-b-2 border-brand-500" />
        </div>
      )}

      {promoBlock?.settings?.paymentsLine && (
        <>
          <div className="container z-20 flex flex-row items-center justify-between w-full px-3 mx-auto md:px-28">
            <span className="hidden text-lg text-white md:block">
              {promoBlock?.settings?.paymentsLine}
            </span>

            <div className="flex flex-row space-x-8">
              {promoBlock?.settings?.paymentmethods?.logoList?.map((item) => (
                <div className="flex-grow">
                  <img
                    src={
                      item?.path?.includes(".svg")
                        ? `${COCKPITCFG.url}/${item.path}`
                        : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${COCKPITCFG.url}/${item.path}`
                    }
                    loading="lazy"
                    className="object-contain h-8"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 z-10 w-full h-96 bg-gradient-to-t from-black to-transparent" />
        </>
      )}
    </section>
  );
};

export default LandingOffer;
